import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import LoaderVideo from "../../static/loader-white.mp4"
import Container from "../components/Container"
import IndexProject from "../components/IndexProject"
import { Layout } from "../components/Layout"
import { Seo } from "../components/Seo"
import { useSiteMetadata } from "../hooks/use-site-metadata"

export default function IndexPage({ data }) {
  const { allMarkdownRemark } = data
  const { nodes: projects } = allMarkdownRemark

  const [loading, setLoading] = useState(true)
  const [isSmallScreen, setIsSmallScreen] = useState(false)

  useEffect(() => {
    const delay = setTimeout(() => {
      setLoading(false)
    }, 2000)
    return () => clearTimeout(delay)
  }, [])

  useEffect(() => {
    const handleResize = () => {
      const isMobileDevice = window.screen.width <= 768
      setIsSmallScreen(isMobileDevice)
    }

    // Initial check
    handleResize()

    // Add event listener for window resize
    window.addEventListener("resize", handleResize)

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const renderLoader = () => (
    <div className="fixed inset-0 flex justify-center items-center w-full h-dvh bg-[#f5f5f4] z-50">
      <video className="w-40 md:w-72" muted autoPlay playsInline loop disableRemotePlayback src={LoaderVideo}>
        <source src={LoaderVideo} type="video/mp4" />
      </video>
    </div>
  )

  return (
    <Layout>
      <main>
        <Container>
          {loading && renderLoader()}
          <div className="grid grid-flow-row-dense grid-cols-2 gap-x-4 gap-y-6 sm:grid-cols-4">
            {projects.map((project) => (
              <IndexProject key={project.id} project={project} isMobile={isSmallScreen} />
            ))}
          </div>
        </Container>
      </main>
    </Layout>
  )
}

export const Head = ({ location, data }) => {
  const { title: defaultTitle, description: defaultDescription, siteUrl, image } = useSiteMetadata()
  const description = data.markdownRemark.frontmatter.description || defaultDescription
  const title = data.markdownRemark.frontmatter.title || defaultTitle
  const { pathname } = location

  return (
    <Seo pathname={pathname} description={description}>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebSite",
          "url": `${siteUrl}${pathname}`,
          "name": title,
          "description": description,
          "headline": "Kevin Kimman Cinematography",
          "image": `${siteUrl}${image}`,
          "author": {
            "@type": "Person",
            "name": "Kevin Kimman",
            "url": "https://www.kevinkimman.com/"
          }
        })}
      </script>
    </Seo>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { category: { eq: "film" } } }
      limit: 22
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      nodes {
        id
        ...IndexProjectFragment
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        description
      }
    }
  }
`
