import { Link, graphql } from "gatsby"
import React from "react"
import Video from "./Video"

export default function IndexProject({ project, isMobile }) {
  const { title, order, big, category, video, image } = project.frontmatter

  const span = big ? "col-span-2 row-span-2" : "max-h-[200px]"

  const renderCategory = () => category === "photography" && "(PHOTOGRAPHY)"

  return (
    <div className={span}>
      <Link to={project.fields.slug}>
        <h3 className="overflow-hidden text-ellipsis whitespace-nowrap text-xs font-normal uppercase">
          {order}
          <span className="mx-4">{title}</span>
          {renderCategory()}
        </h3>
        <Video poster={image} video={video} isMobile={isMobile} />
      </Link>
    </div>
  )
}

export const query = graphql`
  fragment IndexProjectFragment on MarkdownRemark {
    fields {
      slug
    }
    frontmatter {
      title
      order
      big
      featured
      alt
      category
      video {
        webm {
          publicURL
        }
        mp4 {
          publicURL
        }
      }
      image {
        publicURL
        childImageSharp {
          gatsbyImageData(height: 700)
        }
      }
    }
  }
`
